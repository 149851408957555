import { FC, useEffect, useRef, useState } from 'react'

import {
  useAnalytics,
  useFbp,
  usePinterest,
  useRedirect,
  useSetting,
  useSnapChat,
  useTtq
} from '@ui/hooks'
import css from 'styled-jsx/css'
import { REMOVE_ALL_AND_ADD, useNotiContext } from './Notification'
import {
  genSid,
  isApplePayActive,
  useCheckoutService,
  useEventFbId,
  useGlobalState,
  useLocalIsBuyNow,
  useLocalIsPaypalThankyou,
  useLocalIsPuchase,
  useLocalSessionId,
  useLocalShippingAddress,
  useOrderService,
  useRefDep
} from '@libs/client'
import { cartMapping } from '@ui/helpers'
import { useGtag } from '@ui/hooks/useGtag'
import getLogRocket from '@ui/analytics/logRocket'
import { logger } from '@ui/analytics'
import { CustomerAddress } from '@libs/client/order/services'
import { method_type } from '@libs/common/models/constant'
import { useRouter } from 'next/router'
import { useAxon } from '@ui/hooks/useAxon'

interface IPaypalButton {
  paypalEx: S3Types.paygate
  isBuyNow?: boolean
  isPurchase?: boolean
  version?: string
}

const PaypalButton: FC<IPaypalButton> = ({ paypalEx, isBuyNow, isPurchase, version }) => {
  const router = useRouter()
  const redirect = useRedirect()
  const [sellpageSetting] = useSetting('sellpage')
  const { notiDispatch } = useNotiContext()
  const { initPaypal, capturePaypal, cancelPaypal, errorPaypal } = useCheckoutService({
    cartMapping: (cart) => cartMapping(cart, sellpageSetting?.variants?.items ?? {})
  })
  const { updateTaxAmount } = useOrderService()
  const [localShipping] = useLocalShippingAddress()
  const [applePayActive] = useGlobalState(isApplePayActive)

  const [, setLocalSissionId] = useLocalSessionId()
  const [, setEventFbId] = useEventFbId()
  const {
    fbpTrackInitiateCheckout,
    fbpTrackAddPaymentInfo,
    fbpTrackPurchase,
    fbpInitAdvanceMatching
  } = useFbp()
  const { pinterestTrackPurchase } = usePinterest()
  const {
    snapchatTrackInitiateCheckout,
    snapchatTrackAddPaymentInfo,
    snapchatTrackPurchase
  } = useSnapChat()
  const {
    ttqTrackInitiateCheckout,
    // ttqTrackCheckout,
    ttqTrackAddBilling,
    ttqTrackPurchase,
    ttqTrackIdentify
  } = useTtq()
  const {
    gtagTrackBeginCheckout,
    gtagTrackAddPaymentInfo,
    gtagTrackAddShippingInfo,
    gtagTrackPurchase,
    gtagTrackConversionPurchase,
    gtagEnhanceConversion
  } = useGtag()
  const { axonTrackBeginCheckout, axonTrackPurchase } = useAxon()
  const [, setLocalIsPuchase] = useLocalIsPuchase()
  const analytics = useAnalytics()
  const analyticsRef = useRefDep(analytics)
  const localShippingRef = useRefDep(localShipping)
  const currentOrder = useRef<Types.Order | null>(null)
  const [localIsBuyNow] = useLocalIsBuyNow()
  const localIsBuyNowRef = useRefDep(localIsBuyNow)
  const [, setLocalShipping] = useLocalShippingAddress() //
  const isBuyNowCurrent =
    (typeof isBuyNow === 'boolean' ? isBuyNow : localIsBuyNowRef.current) || false
  const [isLoading, setIsLoading] = useState(true)
  const isMobile = process?.browser && window?.innerWidth <= 768
  const { credential_clientid } = paypalEx
  const [, setLocalIsPaypalThankyou] = useLocalIsPaypalThankyou()

  useEffect(() => {
    if (!(window as any).paypal) {
      initPaypalScript()
    }
    const buttonHeight =
      (version === 'apv3' || version === 'apv5' || version == 'apv51' || version === 'apv6') &&
      isBuyNowCurrent
        ? isMobile
          ? 52
          : 55
        : version === 'apv1' || version === 'apv2' || version === 'apv21' || version === 'apv3'
        ? 50
        : 55

    const interval = setInterval(() => {
      if ((window as any).paypal) {
        setIsLoading(false)
        // @ts-ignore
        ;(window.paypal as any)
          .Buttons({
            style: {
              ...style,
              height: buttonHeight
            },
            // style: style,
            createOrder: createOrder,
            onApprove: onApprove,
            onShippingChange: onShippingChange,
            onCancel: onCancel,
            onError: onError
          })
          .render('.js-paypal-button')
          .catch(console.warn)
        clearInterval(interval)
      } else {
        initPaypalScript()
      }
    }, 300)
    return () => {
      clearInterval(interval)
    }
  }, [])

  function initPaypalScript() {
    const PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?client-id=${credential_clientid}&disable-funding=card,paylater`
    const script = document.createElement('script')
    script.setAttribute('src', PAYPAL_SCRIPT)
    document.head.appendChild(script)
  }

  async function createOrder() {
    getLogRocket().logRocket?.init()
    console.log('createOrder')

    if (!isPurchase) {
      return notiDispatch({
        payload: {
          content: `You cannot checkout. This page is for preview only.`,
          type: 'is-danger'
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
    }

    try {
      // LogRocket.track('PaypalOrderCreated')
      const result = await initPaypal(isBuyNowCurrent) // Important: must be return an order_id for `createOrder` function

      if (result?.status === 'COMPLETED') {
        notiDispatch({
          type: REMOVE_ALL_AND_ADD,
          payload: {
            content: 'Your payment was successfully.',
            type: 'is-success'
          }
        })
        setLocalIsPaypalThankyou(false)
        const query = router.query
        delete query.vb
        delete query.subpath
        redirect('/thankyou', {
          query: { ...query, afs: result?.order?.id },
          keepQuery: false
        })
      }
      if (!isBuyNowCurrent) {
        fbpTrackInitiateCheckout(result?.order?.items, result?.order?.items_amount)
        snapchatTrackInitiateCheckout(result?.order?.items, result?.order?.items_amount)
        ttqTrackInitiateCheckout(result?.order?.items, result?.order?.items_amount)
        // ttqTrackCheckout(result?.order?.items, result?.order?.items_amount)
        gtagTrackBeginCheckout(result?.order?.items, result?.order?.items_amount)
        axonTrackBeginCheckout(result?.order?.items, result?.order?.items_amount)
        logger.logProductEvent('checkout_paypal_cart')
        if (router.query?.afs) logger.logProductEvent('aftersale_checkout_paypal_cart')
      } else {
        logger.logProductEvent('checkout_paypal_buy_now')
        if (router.query?.afs) logger.logProductEvent('aftersale_checkout_paypal_buy_now')
      }

      currentOrder.current = result?.order ?? null

      return result?.orderId
    } catch (err: any) {
      if (err?.message && err?.status) {
        notiDispatch({
          payload: {
            content: err.message || 'Your payment was failed',
            type: 'is-danger'
          },
          type: REMOVE_ALL_AND_ADD
        })
      } else {
        notiDispatch({
          payload: {
            content: err?.response?.data?.message || 'Your payment was failed',
            type: 'is-danger'
          },
          type: REMOVE_ALL_AND_ADD
        })
      }
    }
  }

  async function onApprove(data: any, actions: any) {
    fbpTrackAddPaymentInfo(currentOrder.current?.items, currentOrder.current?.items_amount)
    snapchatTrackAddPaymentInfo(currentOrder.current?.items, currentOrder.current?.items_amount)
    ttqTrackAddBilling(currentOrder.current?.items, currentOrder.current?.items_amount)
    gtagTrackAddPaymentInfo(
      currentOrder.current?.items,
      currentOrder.current?.items_amount,
      'Paypal'
    )
    gtagTrackAddShippingInfo(currentOrder.current?.items, currentOrder.current?.items_amount)

    logger.logProductEvent('paid_paypal')
    try {
      const result = await capturePaypal(data, actions, isBuyNowCurrent)

      notiDispatch({
        type: REMOVE_ALL_AND_ADD,
        payload: {
          content: 'Your payment was successfully.',
          type: 'is-success'
        }
      })

      // Analytics.track('PURCHASE', {
      //   extra: { orderId: this.order.id }
      // })
      // LogRocket.track('Purchased')
      // LogRocket.track('PaypalOrderPurchased')

      fbpTrackPurchase(result?.order.items, result?.order.amount)
      fbpInitAdvanceMatching(result?.order?.shipping)
      pinterestTrackPurchase(result?.order.amount)
      snapchatTrackPurchase(result?.order.items, result?.order.amount)
      ttqTrackIdentify(result?.order?.shipping)
      ttqTrackPurchase(result?.order.items, result?.order.amount)
      gtagTrackPurchase(result?.order)
      axonTrackPurchase(result?.order)
      gtagTrackConversionPurchase(
        `AW-${analyticsRef?.current?.google_conversion_id || ''}/${
          analyticsRef?.current?.google_conversion_label || ''
        }`,
        result?.order
      )
      gtagEnhanceConversion(result?.order)
      setLocalSissionId(genSid())
      setEventFbId(genSid())
      setLocalIsPuchase(true)
      if (!isBuyNowCurrent) {
        logger.logProductEvent('payment_success_paypal_cart')
      } else {
        logger.logProductEvent('payment_success_paypal_buy_now')
      }
      logger.logProductEvent('payment_success')
      if (router.query?.afs || '') {
        logger.logProductEvent('aftersale_payment_success_paypal')
        logger.logProductEvent('aftersale_payment_success')
      }
      setLocalIsPaypalThankyou(false)
      const query = router.query
      delete query.vb
      delete query.subpath
      redirect('/thankyou', {
        query: { ...query, afs: result?.order?.id },
        keepQuery: false
      })
    } catch (err: any) {
      if (err?.message && err?.status) {
        notiDispatch({
          payload: {
            content: err.message || 'Your payment was failed',
            type: 'is-danger'
          },
          type: REMOVE_ALL_AND_ADD
        })
      } else {
        notiDispatch({
          payload: {
            content: err?.response?.data?.message || 'Your payment was failed',
            type: 'is-danger'
          },
          type: REMOVE_ALL_AND_ADD
        })
      }
      logger.logProductEvent('payment_failure')
    }
  }

  async function onShippingChange(data: { shipping_address: CustomerAddress }, actions: any) {
    // Get tax rate for state
    try {
      setLocalShipping(data?.shipping_address)
      const order = await updateTaxAmount(
        data?.shipping_address as CustomerAddress,
        method_type.PAYPALEX
      )
      return actions.order.patch([
        {
          op: 'replace',
          path: "/purchase_units/@reference_id=='default'/amount",
          value: {
            currency_code: 'USD',
            value: (order?.amount || 0).toFixed(2),
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: (order?.items_amount || 0).toFixed(2)
              },
              shipping: {
                currency_code: 'USD',
                value: (order?.shipping_amount || 0).toFixed(2)
              },
              discount: {
                currency_code: 'USD',
                value: (order?.discount_amount || 0).toFixed(2)
              },
              handling: {
                currency_code: 'USD',
                value: (order?.handling_fee || 0).toFixed(2)
              },
              tax_total: {
                currency_code: 'USD',
                value: (order?.tax_amount || 0).toFixed(2)
              }
            }
          }
        }
      ])
    } catch (error) {
      const order = await updateTaxAmount(
        data?.shipping_address as CustomerAddress,
        method_type.PAYPALEX
      )
      return actions.order.patch([
        {
          op: 'replace',
          path: "/purchase_units/@reference_id=='default'/amount",
          value: {
            currency_code: 'USD',
            value: (order?.amount || 0).toFixed(2),
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: (order?.items_amount || 0).toFixed(2)
              },
              shipping: {
                currency_code: 'USD',
                value: (order?.shipping_amount || 0).toFixed(2)
              },
              discount: {
                currency_code: 'USD',
                value: (order?.discount_amount || 0).toFixed(2)
              },
              handling: {
                currency_code: 'USD',
                value: (order?.handling_fee || 0).toFixed(2)
              },
              tax_total: {
                currency_code: 'USD',
                value: (order?.tax_amount || 0).toFixed(2)
              }
            }
          }
        }
      ])
    }
  }

  function onError(err: Error) {
    console.warn('Error while processing the payment with Paypal', err)
    updateTaxAmount(localShippingRef.current || {}, method_type.CREDITCARD)
    errorPaypal()
  }

  function onCancel(data: any) {
    updateTaxAmount(localShippingRef.current || {}, method_type.CREDITCARD, true)
    cancelPaypal(data, isBuyNowCurrent)
  }

  return (
    <>
      <style jsx>{paypalStyle}</style>
      <div
        className={`paypal-button ${
          (version == 'apv5' || version == 'apv51') && applePayActive == 'active' ? 'apv5' : ''
        }`}
      >
        {isLoading ? (
          <button
            className={`loading-button button is-fullwidth is-warning is-loading mb-1 ${
              version === 'apv1' ||
              version === 'apv2' ||
              version === 'apv21' ||
              version === 'apv3' ||
              version === 'apv5' ||
              version == 'apv51'
                ? 'with-applepay'
                : ''
            }`}
          />
        ) : null}
        <div className="js-paypal-button" />
      </div>
    </>
  )
}

export default PaypalButton

const style = {
  color: 'gold',
  height: 55,
  layout: 'horizontal',
  size: 'medium',
  shape: 'rect',
  tagline: false,
  fundingicons: 'false'
}

const paypalStyle = css`
  .paypal-button {
    .loading-button {
      background-color: #ffc43a !important;
      height: 55px;
    }
    .with-applepay.loading-button {
      height: 50px;
    }
    &.apv5 {
      flex-basis: 50%;
      min-width: 140px;
    }
  }
`
